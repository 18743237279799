import React from "react";

import Layout from "../../../components/Layout";
import PublishingSection from "../../../content/PublishingSection";

const Publishing = (props) => {
  return (
    <Layout>
      <PublishingSection isPage />
    </Layout>
  );
};

export default Publishing;
