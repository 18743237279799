import React from "react";
import {
  Link,
  UnorderedList,
  ListItem,
  Table,
  Tr,
  Th,
  Td,
  Box,
  Tbody,
} from "@chakra-ui/react";
import { Link as GatsbyLink } from "gatsby";

import Section from "../ui/common/Section";
import Paragraph from "../ui/common/Paragraph";

const items = [
  {
    title: "Publisher",
    text: (
      <>
        <Paragraph>
          <i>microPublication</i> and <i>microPublication Biology</i> is owned
          by the California Institute of Technology and published by the{" "}
          <Link href="https://www.library.caltech.edu/" isExternal>
            Caltech Library
          </Link>
          .
        </Paragraph>
        <Paragraph>
          The Caltech Library advances the Institute’s mission to expand human
          knowledge by catalyzing information discovery and sharing. The Library
          maintains extensive research collections, a variety of study spaces,
          state-of-the-art knowledge management platforms, and a user-focused
          program of instruction and outreach that enhances student success at
          Caltech. A flagship service of the Caltech Library is publishing of
          Caltech-created research outputs including theses, technical reports,
          digital projects, research notebooks, datasets and software; the
          journal microPublicaiton Biology; and Open Educational resources
          developed for teaching and learning. The selection process for the
          Library’s publishing program requires partnership with one or more
          Caltech research groups or Academic divisions to ensure the
          sustainability of content and funding.
        </Paragraph>

        <Paragraph>
          The University Librarian serves as the Publisher of Record for this
          academic and research publication program.
        </Paragraph>
        <Box borderWidth="1px" borderRadius="lg">
          <Table variant="striped">
            <Tbody>
              <Tr>
                <Th>Journal</Th>
                <Th>eISSN</Th>
                <Th>Year Started</Th>
                <Th>Archival Server</Th>
                <Th>DOI Source</Th>
              </Tr>
              <Tr>
                <Td>microPublication Biology</Td>
                <Td>2578-9430</Td>
                <Td>2016</Td>
                <Td>California Institute of Technology Library</Td>
                <Td>DataCite</Td>
              </Tr>
            </Tbody>
          </Table>
        </Box>
      </>
    ),
  },
  {
    title: "Data Sharing Policy",
    text: (
      <Paragraph>
        <i>microPublication</i> journals work directly with community supporting
        knowledgebases during the submission process. You can read our data
        sharing policy{" "}
        <GatsbyLink to="/data-sharing-policy">
          <u>here</u>
        </GatsbyLink>
        .
      </Paragraph>
    ),
  },
  {
    title: "Publication schedule",
    text: (
      <Paragraph>
        We publish on a rolling basis. To support requirements for Google
        Scholar indexing, we adopted their suggested time based issue structure
        where articles are grouped into symbolic issues based on the month in
        which they were published.
      </Paragraph>
    ),
  },
  {
    title: "Open access statement",
    text: (
      <>
        <Paragraph>
          <i>microPublication Biology</i>​ is a fully open access journal as
          defined by OASPA and SPARC. <i>microPublication Biology</i> publishes
          under the terms of the Creative Commons Attribution 4.0 International{" "}
          (
          <Link href="https://creativecommons.org/licenses/by/4.0/" isExternal>
            CC BY 4.0
          </Link>
          ) license, which permits unrestricted use, distribution, and
          reproduction in any medium, provided the original author(s) and source
          are credited.
        </Paragraph>
        <Paragraph>
          Data from accepted publications are made available to the public
          through delivery for curation and integration into Model Organism
          Databases or authoritative repositories.
        </Paragraph>
      </>
    ),
  },
  {
    title: "Indexed by",
    text: (
      <UnorderedList>
        <ListItem>PubMed</ListItem>
        <ListItem>PubMed Central</ListItem>
        <ListItem>Europe PMC</ListItem>
        <ListItem>DataCite</ListItem>
        <ListItem>Directory of Open Access Scholarly Resources (ROAD)</ListItem>
        <ListItem>Directory of Open Access Journals (DOAJ)</ListItem>
        <ListItem>Google</ListItem>
        <ListItem>Google Scholar</ListItem>
      </UnorderedList>
    ),
  },
  {
    title: "Advertising",
    text: <Paragraph>No Advertising is accepted.</Paragraph>,
  },
];

const PublishingSection = (props) => {
  const { publishRef, isPage } = props;
  return (
    <Section
      items={items}
      title="Publishing Information"
      sectionRef={publishRef}
      isPage={isPage}
    />
  );
};

export default PublishingSection;
